<template>
    <div class="">
<a @click="print" class="btn btn-secondary text-dark">Print</a>

        <div class="mb-3" style="" id="printable">
        <div class="text-center" style="width: 400px;margin:10px auto;">
            <div class="">
            <abdavid-print></abdavid-print>
            </div>
            <span style="text-decoration: underline;font-size: 30px; color:black; text-align:center;margin-top:-30px">Visitor</span>
            <br>
            <span style="font-size: 55px; color:black; text-align:center">{{data.name}}</span>
             <br>
            <span style="font-size: 25px; color:black; text-align:center">{{data.company}}</span>
        </div>
       
    </div>
    </div>
</template>

<script>
import AbdavidPrint from './AbdavidPrint.vue';
    export default {
  components: { AbdavidPrint },
        data(){
            return {

            }
        },
        props:{
            data:{}
        },
        methods:{
            print(){
                // window.print();
                let contents = document.getElementById("printable").innerHTML;
                let frame1 = document.createElement('iframe');
                frame1.name = "frame1";
                frame1.style.position = "absolute";
                frame1.style.top = "-1000000px";
                document.body.appendChild(frame1);
                let frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
                frameDoc.document.open();
                frameDoc.document.write('<html lang="en"><head><title>Visitor Tag</title>');
                // frameDoc.document.write('<link rel="stylesheet" type="text/css" href=""/>');
                frameDoc.document.write('</head><body style=" text-align:center;">');
                frameDoc.document.write(contents);
                frameDoc.document.write('</body></html>');
                frameDoc.document.close();
                setTimeout(function () {
                    window.frames["frame1"].focus();
                    window.frames["frame1"].print();
                    document.body.removeChild(frame1);
                }, 500);
                return false;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>