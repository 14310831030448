<template>
    <div>
        <form @submit.prevent="addmeet" method="post" class="mb-3">
            <div class="form-group">
                <label for="" class="text-white">Name</label>
                <input v-model="staff.name" type="text" class="form-control" />
                <!-- <span class="text-danger">Ieeror</span> -->
            </div>
            <div class="form-group">
                <label for="" class="text-white">Phone number</label>
                <input v-model="staff.phone" type="text" class="form-control" />
            </div>
            <div class="form-group">
                <label for="" class="text-white">Username</label>
                <input v-model="staff.username" type="text" class="form-control" />
            </div>
            <div class="form-group">
                <label for="" class="text-white">Role</label>
                <select v-model="staff.role" id="" class="form-control">
                    <option value="">select role</option>
                    <option>Admin</option>
                    <option>Receptionist</option>
                </select>
            </div>
                <button type="submit" class="btn btn-secondary text-dark">
                {{ user != null ? "Update" : "Save" }}
            </button>
        </form>
            <button @click="reset(staff.mask)" class="btn btn-danger" v-if="user">Reset password</button>
    </div>
</template>

<script>
import axiosClient from "@/axios";
import toast from "./Toast.vue";

export default {
    data() {
        return {
            staff: {},
            departments:{}
        };
    },
    props: {
        user: {},
    },
    mounted() {
        if (this.user) {
            this.staff = this.user;
        }
    },
    methods: {
        // getDepts() {
        //     axiosClient.get("departments").then(({ data }) => {
        // // console.log(data.data);        
        //         this.departments = data.data;
        //     });
        // },
        addmeet() {
            if (!this.user) {
                axiosClient
                    .post("add-user", this.staff)
                    .then(({ data }) => {
                        toast.clear();
                        toast.success(data.message);
                        this.$emit("done");
                    })
                    .catch(({ response }) => {
                        console.log(response.data.errors);
                        toast.error("All Fields are required");
                    });
            } else {
                axiosClient
                    .post("update-user", this.staff)
                    .then(({ data }) => {
                        toast.clear();
                        toast.success(data.message);
                        this.$emit("done");
                    })
                    .catch(({ response }) => {
                        console.log(response.data.errors);
                        toast.error("All Fields are required");
                    });
            }
        },
        reset(id){
            if(confirm('Confirming password reset?')){
                axiosClient.post('user-reset',{mask: id}).then(({data})=>{
                toast.clear();
                toast.success(data.message);
                // console.log(data);
                this.$emit('changed');
            })
            } 
        }
    },
};
</script>

<style lang="scss" scoped></style>
