<template>
    <div>
        <h3>Meetings</h3>
        <card-component>
            <a @click="addmeet" class="btn btn-primary col-sm-1 text-white mb-3">Add Meeting</a>
              <table-layout>
            <template #head>
                <th>#</th>
                <th>Date</th>
                <th>Starting time</th>
                <th>Ending time</th>
                <th>Name</th>
                <th>Meeting Room</th>
                <th>Host</th>
                <th>Participants</th>
                <th>Actions</th>
                
            </template>
            <tr v-for="(meeting, index) in meetings" :key="meeting">
                <td>{{ index+1 }}</td>
                <td>{{ dateformat(meeting.date) }}</td>
                <td>{{ timeformat(meeting.date) }}</td>
                <td>{{ timeformat(meeting.updated_at) }}</td>
                <td>{{ meeting.name }}</td>
                <td>{{ meeting.room }}</td>
                <td>{{ meeting.host }}</td>
                <td>{{ meeting.participants }}</td>
                <td><a @click="editmeet(meeting)" class="btn btn-secondary">Edit</a> | <a @click="delmeet(meeting.id)" class="btn btn-danger">Delete</a></td>
            </tr>
            <!-- {{ meetings }} -->
        </table-layout>
        </card-component>
      
    </div>
</template>

<script>
import CardComponent from '../components/CardComponent.vue'
import TableLayout from '../components/TableLayout.vue'
import axiosClient from '@/axios'
import moment from 'moment'
import toast from '@/components/Toast.vue'
import AddMeeting from '@/components/AddMeeting.vue'

    export default {
  components: { TableLayout, CardComponent, AddMeeting },
  data(){
    return {
        meetings:'',
    }
  },
  mounted(){
  this.getMeetings();
  },
  methods:{
    getMeetings(){
        axiosClient.get('meetings').then(({data})=>{
        this.meetings = data.data;
    })
    },
    timeformat(time){
        if(time){

            return moment(time).format('h:mm a');
        }
    },
    dateformat(date){
       return moment(date).format('ddd, Do MMM Y');
    },
    addmeet(){
        toast.clear();
        toast({component: AddMeeting,listeners:{done:()=>{this.getMeetings()}}},
        {timeout:false, icon:false, closeOnClick:false});
    },
    editmeet(meeting){
        toast.clear();
        toast({component: AddMeeting,props:{emeeting:meeting},listeners:{done:()=>{this.getMeetings()}}},
        {timeout:false, icon:false, closeOnClick:false});
    },
    delmeet(id){
        if(confirm("Confirm you are deleting meeting?")){
            axiosClient.post('delete-meeting',{meeting:id}).then(({data})=>{
                toast.success(data.message);
                this.getMeetings();
            })
        }
    }
  }
        
    }
</script>

<style scoped>
/* .Vue-Toastification__toast{
    background: green;
} */
</style>