<template>
    <div>
        <form @submit.prevent="addmeet" method="post">
            <div class="d-flex">
                 <div class="form-group">
                <label for="" class="text-white">Name</label>
                <input v-model="staff.name" type="text" class="form-control" />
                <!-- <span class="text-danger">Ieeror</span> -->
            </div>
            <div class="form-group ml-3">
                <label for="" class="text-white">Phone number</label>
                <input v-model="staff.phone" type="text" class="form-control" />
            </div>
            </div>
           
            <div class="form-group">
                <label for="" class="text-white">Email</label>
                <input
                    v-model="staff.email"
                    type="email"
                    class="form-control"
                />
            </div>
            <div class="d-flex">
                 <div class="form-group">
                <label for="" class="text-white">Company</label>
                <input
                    v-model="staff.company"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group ml-3">
                <label for="" class="text-white">Date to visit</label>
                <input
                    v-model="staff.visit_date"
                    type="date"
                    class="form-control"
                />
            </div>
            </div>
           <div class="d-flex">
             <div class="form-group">
                <label for="" class="text-white">Purpose</label>
                <select v-model="staff.purpose" id="" class="form-control">
                    <option value="">select purpose</option>
                    <option>Delivery/Vendor/Statutory inspections</option>
                    <option>Meeting</option>
                    <option>Interview</option>
                    <option>Service provision</option>
                    <option>Business Development</option>
                    <option>Emergency</option>
                    <option>Repairs</option>
                    <option>Other</option>
                    <!-- <option>Walk-in Client/ General inquiry </option> -->
                </select>
            </div>
            <div class="form-group ml-3">
                <label for="" class="text-white">Meeting</label>
                <select v-model="staff.meeting" id="" class="form-control">
                    <option value="">select meeting</option>
                    <option v-for="meeting in meetings" :key="meeting" :value="assign(meeting.id)" >{{ meeting.name }} - {{ dateformat(meeting.date) }}</option>
                </select>
            </div>
           </div>
           
            <div class="form-group">
                <label for="" class="text-white">Employee</label>
                <select v-model="staff.deliveryto" id="" class="form-control">
                    <option value="">select employee</option>
                    <option v-for="employee in employees" :key="employee">{{ employee.name }}</option>
                </select>
            </div>
            <button type="submit" class="btn btn-secondary text-dark">
                {{ visitor != null ? "Update" : "Save" }}
            </button>
        </form>
    </div>
</template>

<script>
import axiosClient from "@/axios";
import toast from "./Toast.vue";
import moment from "moment";

export default {
    data() {
        return {
            staff: {},
            meetings:{},
            employees:{}
        };
    },
    props: {
        visitor: {},
    },
    mounted() {
        if (this.visitor) {
            this.staff = this.visitor;
        }
        this.getMeetings();
        this.getEmps();
    },
    methods: {
        addmeet() {
            if (!this.visitor) {
                axiosClient
                    .post("employee/add-visitor", this.staff)
                    .then(({ data }) => {
                        console.log(this.staff);
                        toast.clear();
                        toast.success(data.message);
                        this.$emit("done");
                    })
                    .catch(({ response }) => {
                        console.log(response.data);
                        toast.error("All Fields are required");
                    });
            } else {
                axiosClient
                    .post("visitor/update-visitor", this.staff)
                    .then(({ data }) => {
                        toast.clear();
                        toast.success(data.message);
                        this.$emit("done");
                    })
                    .catch(({ response }) => {
                        console.log(response.data.errors);
                        toast.error("All Fields are required");
                    });
            }
        },
        getMeetings(){
            axiosClient.get('meetings').then(({data})=>{
                this.meetings = data.data;
            })
        },
        getEmps(){
            axiosClient.get('employees').then(({data})=>{
                this.employees = data.data;
            })
        },
        dateformat(date){
           return moment(date).format('ddd, Do MMM')
        },
        assign(meeting){
            return meeting;
        }
    },
};
</script>

<style lang="scss" scoped></style>
