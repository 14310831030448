<template>
    <div>
        <a @click="adduser" class="btn btn-primary btn-sm text-white">Add User</a>
        <CardComponent class="col-lg-6">

       <TableLayout>
        <template #head>
            <th>#</th>
            <th>Name</th>
            <th>Username</th>
            <th>Role</th>
            <th>Action</th>
        </template>
        <tr v-for="(user,index) in users" :key="user">
            <td>{{ index+1 }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.username }}</td>
            <td>{{ user.role }}</td>
            
            <td><a @click="edit(user)" class="btn btn-secondary">Edit</a> | <a @click="erase(user.mask)" class="btn btn-danger">Delete</a></td>
        </tr>
       </TableLayout>
        </CardComponent>
    </div>
</template>

<script>
import CardComponent from '@/components/CardComponent.vue';
import axiosClient from '@/axios'
import toast from '@/components/Toast.vue'
import AddUserVue from '../components/AddUser.vue';
// import moment from 'moment'
// const formattedDate = moment().format('H:m A')
// console.log(formattedDate)

    export default {
    components: { CardComponent },
    data(){
        return {
            users:''
        }
    },
    mounted(){
       this.getUsers();
    },
    methods:{
        getUsers(){
            axiosClient.get('/users').then(({data})=>{
            this.users = data.data;
        })
        },
        adduser(){
            toast({component: AddUserVue, listeners:{done: ()=>{
                toast.clear();
               this.getUsers();
            }}},{timeout:false, icon:false, closeOnClick:false});
        },
        edit(user){
            toast({component: AddUserVue,props:{user:user} ,listeners:{done: ()=>{
                toast.clear();
               this.getUsers();
            }}},{timeout:false, icon:false, closeOnClick:false});
        },
        erase(user){
            if(confirm('Confirming user delete?')){
                axiosClient.post('delete-user', {user:user}).then(({data})=>{
                toast.success(data.message);
                this.getUsers();
            })
            }
            
        }
    }
}
</script>

<style lang="scss" scoped>

</style>