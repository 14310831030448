<template>
    <div class="d-flex">
        <div class="">
            <label for="checkin" class="font-19">Check in image</label>
            <img :src="baseimg+checkinImg" style="width:400px;" alt="" srcset="">
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                baseimg: 'http://172.161.150.8/visitors/'
                // baseimg: 'https://api.abdavid.wowlogbook.com/visitors/'
            }
        },
        props:{
            checkinImg:'',
        }
    }
</script>

<style lang="scss" scoped>

</style>