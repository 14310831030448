<template>
    <div>
    <a @click="reload" class="btn btn-secondary bg-primary text-white mt-0 mb-4"><strong class="font-18">Reload data</strong> </a>

        <h3>Visitors report - {{ day }}</h3>
        <card-component>
            <div class="d-flex justify-content-between">
            <a @click="filter" class="btn btn-primary text-white col-sm-2 mb-3">View history</a>
            <a @click="excel" class="btn btn-danger text-white col-sm-2 mb-3">Export to Excel</a>
            </div>
            <table-layout>
                <template #head>
                    <th>#</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Code</th>
                    <th>Purpose</th>
                    <th>Host</th>
                    <th>Time in</th>
                    <th>Time out</th>
                    <th>Action</th>
                </template>
                <tr v-for="(service, index) in services" :key="service">
                    <td>{{ index + 1 }}</td>
                    <td>{{ service.name }}</td>
                    <td>{{ service.phone }}</td>
                    <td>{{ service.code }}</td>
                    <td>{{ service.purpose }}</td>
                    <td>{{ service.host }}</td>
                    <td>{{ timeformat(service.time_in) }}</td>
                    <td>{{ timeformat(service.time_out) }}</td>
                    <td><a @click="print(service)" class="btn btn-primary text-white">Print tag</a> | <a @click="image(service.image)" class="btn btn-warning text-white">View image</a></td>
                </tr>
                <!-- {{ services }} -->
            </table-layout>
        </card-component>
    </div>
</template>

<script>
import CardComponent from "../components/CardComponent.vue";
import TableLayout from "../components/TableLayout.vue";
import axiosClient from "@/axios";
import moment from "moment";
import HistoryComponent from "@/components/HistoryComponent.vue";
import toast from "@/components/Toast.vue";
import TagPrintVue from '../components/TagPrint.vue';
import { json2excel, excel2json } from "js2excel";
import VisitorImageVue from '../components/VisitorImage.vue';


export default {
    components: { TableLayout, CardComponent },
    data() {
        return {
            services: "",
            day: '',
            timer: '',
            nae:''
        };
    },
    mounted() {
        this.getlogs();
        // this.refresh();
    },
    methods: {
     employ(emp){
            var name ='h';
            axiosClient("employee/"+emp).then(({data})=>{
                // if(data.data){
                   name = data.data;
                // }
                // retur n
            }).catch((response)=>{
                console.log(response);
            })
            return name;
        },
        refresh(){
        this.timer = setInterval(this.getlogs,5000)
        },
        getlogs(purpose) {
            axiosClient
                .post("logs", { purpose: purpose })
                .then(({ data }) => {
                    this.services = data.data.logs;
                    this.day = data.data.date;
                });
        },
        timeformat(time) {
            if (time) {
                return moment(time).format("h:mm a");
            }
        },
        dateformat(date) {
            return moment(date).format("ddd, Do MMM Y");
        },
        filter(){
            toast.clear();
            toast({component: HistoryComponent, listeners:{viewReport: (data)=>{
                toast.clear();
                clearInterval(this.timer);
                this.services = {},
                this.services = data.history;
                this.day = data.range;
            }},props:{which:'visitor'}},{timeout:false, icon:false, closeOnClick:false});
        },
        print(data){
            toast.clear();
           toast({component: TagPrintVue,props:{data: data}},{timeout:false, icon:false, closeOnClick:false});
           
        },
        excel(){
            try {
                json2excel({
                    data: this.services,
                    name: "AB&DAVID Visitors report ",
                    formateDate: "yyyy/mm/dd",
                });
            } catch (e) {
                console.error("export error");
            }
        },
        image(checkin){
            toast.clear();
            toast({component:VisitorImageVue,props:{checkinImg:checkin}},{timeout:false, icon:false, closeOnClick:false})
        },
        reload(){
            this.getlogs();
        }
        
    },
};
</script>

<style lang="scss" scoped></style>
