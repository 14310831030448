<template>
    <div>
        <h3>Meeting Log - {{ dateformat() }}</h3>
        <card-component>
              <table-layout>
            <template #head>
                <th>#</th>
                <th>Name</th>
                <th>Time in</th>
                <th>Time out</th>
            </template>
            <tr v-for="(visitor, index) in logs" :key="visitor">
                <td>{{ index+1 }}</td>
                <td>{{ visitor.name }}</td>
                <td>{{ timeformat(visitor.created_at) }}</td>
                <td>{{ timeformat(visitor.updated_at) }}</td>
            </tr>
            <!-- {{ visitors }} -->
        </table-layout>
        </card-component>
      
    </div>
</template>

<script>
import CardComponent from '../components/CardComponent.vue'
import TableLayout from '../components/TableLayout.vue'
import axiosClient from '@/axios'
import moment from 'moment'

    export default {
  components: { TableLayout, CardComponent },
  data(){
    return {
        logs:'',
    }
  },
  mounted(){
    axiosClient.post('logs',{purpose: 'Meeting'}).then(({data})=>{
        this.logs = data.data;
    })
  },
  methods:{
    timeformat(time){
        if(time){
            return moment(time).format('h:m a');
        }
    },
    dateformat(date){
       return moment(date).format('ddd, Do MMM Y');
    }
  }
        
    }
</script>

<style lang="scss" scoped>

</style>