<template>
  <div id="app">
    <div class="main-wrapper main-wrapper-1">
      <div class="navbar-bg"></div>
      <nav class="navbar navbar-expand-lg main-navbar sticky">
        <div class="form-inline mr-auto">
          <ul class="navbar-nav mr-3">
            <li>
              <a href="#" class="nav-link nav-link-lg fullscreen-btn">
                <i class="fas fa-expand text-dark"></i>
              </a>
            </li>
            <!-- <li>
              <form class="form-inline mr-auto">
                <div class="search-element">
                  <input
                    class="form-control"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    data-width="200"
                  />
                  <button class="btn" type="submit">
                    <i class="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </li> -->
          </ul>
        </div>
        <ul class="navbar-nav navbar-right">
          <!-- <li>
            <a @click="toggle" class="btn btn-warning">Refresh</a>
          </li> -->
          <li class="dropdown">
            <a
              href="#"
              data-toggle="dropdown"
              class="nav-link dropdown-toggle nav-link-lg nav-link-user"
            >
              <!-- <img
                alt="image"
                src="assets/img/user.png"
                class="user-img-radious-style" /> -->
              <i class="fas fa-user text-dark"></i>
              <span class="d-sm-none d-lg-inline-block"></span
            ></a>
            <div class="dropdown-menu dropdown-menu-right pullDown">
              <div class="dropdown-title">Hello User</div>
              <a @click="changepass" class="dropdown-item has-icon">
                <i class="far fa-user"></i> Change Password
              </a>
              <!-- <a href="timeline.html" class="dropdown-item has-icon">
                <i class="fas fa-bolt"></i>
                Activities
              </a>
              <a href="#" class="dropdown-item has-icon">
                <i class="fas fa-cog"></i>
                Settings
              </a> -->
              <div class="dropdown-divider"></div>
              <a @click="logout" class="dropdown-item has-icon text-danger">
                <i class="fas fa-sign-out-alt"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
      <div class="main-sidebar sidebar-style-2">
        <aside id="sidebar-wrapper">
          <div class="sidebar-brand d-grid align-items-center">
            <router-link to="/dashboard">
              <!-- Generator: Adobe Illustrator 19.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
              <ABDavidLogo style="width: 200px; height: 100px" />
            </router-link>
            <span class="logo mb-4 text-white">Logbook</span>
          </div>
          <ul class="sidebar-menu mt-5">
            <router-link to="/dashboard">
              <li class="dropdown">
                <a class="nav-link text-white"
                  ><i class="fas fa-home font-20"></i
                  ><span class="text-white-all">Dashboard</span></a
                >
              </li>
            </router-link>
            <div class="" v-if="user() == 'Admin'">
              <!-- <router-link to="/departments">
            <li class="dropdown">
                <a class="nav-link text-white"
                  ><i class="fas fa-building font-20"></i><span>Departments</span></a
                >
            </li>
              </router-link> -->
              <router-link to="/employees">
                <li class="dropdown">
                  <a class="nav-link text-white"
                    ><i class="fas fa-user-check font-20"></i><span>Employees</span></a
                  >
                </li>
              </router-link>
              <router-link to="/meetings">
                <li class="dropdown">
                  <a class="nav-link text-white"
                    ><i class="fas fa-users font-20"></i><span>Meetings</span></a
                  >
                </li>
              </router-link>
              <router-link to="/booked">
                <li class="dropdown">
                  <a class="nav-link text-white"
                    ><i class="fas fa-user-plus font-20"></i
                    ><span>Booked Visitors</span></a
                  >
                </li>
              </router-link>
              <li class="dropdown">
              <a href="#" class="menu-toggle nav-link has-dropdown text-white" id="empsdrop"
                ><i class="fas fa-pen font-20 "></i><span>Employee Report</span></a
              >
              <ul class="" id="emps_menu">
                <li><router-link to="/employeeslog" class="text-white">View all report</router-link></li>
                <li><router-link to="/employeeslog-single" class="text-white">View individual report</router-link></li>
              </ul>
            </li>
              <!-- <router-link to="/employeeslog">
                <li class="dropdown"> <router-link <router-link to="/employeeslog">
                <li class="dropdown">
                  <a class="nav-link text-white"
                    ><i class="fas fa-pen font-20"></i><span>Employee Report</span></a
                  >
                </li>
              </router-link> to="/employeeslog">
                <li class="dropdown">
                  <a class="nav-link text-white"
                    ><i class="fas fa-pen font-20"></i><span>Employee Report</span></a
                  >
                </li>
              </router-link>
                  <a class="nav-link text-white"
                    ><i class="fas fa-pen font-20"></i><span>Employee Report</span></a
                  >
                </li>
              </router-link> -->
              <router-link to="/logs">
                <li class="dropdown">
                  <a class="nav-link text-white"
                    ><i class="fas fa-pen font-20"></i><span>Visitor Report</span></a
                  >
                </li>
              </router-link>
                <router-link to="/users">
                <li class="dropdown">
                  <a class="nav-link text-white"
                    ><i class="fas fa-users-cog font-20"></i><span>Users</span></a
                  >
                </li>
              </router-link>
            </div>

            <!-- <router-link to="/settings">
            <li class="dropdown">
                <a class="nav-link text-white"
                  ><i class="fas fa-cog font-20"></i><span>Settings</span></a
                >
            </li>
              </router-link> -->

            <div class="" v-else>
              <router-link to="/booked">
                <li class="dropdown">
                  <a class="nav-link text-white"
                    ><i class="fas fa-user-plus font-20"></i
                    ><span>Booked Visitors</span></a
                  >
                </li>
              </router-link>
              <router-link to="/meetings">
                <li class="dropdown">
                  <a class="nav-link text-white"
                    ><i class="fas fa-users font-20"></i><span>Meetings</span></a
                  >
                </li>
              </router-link>
             
              <router-link to="/employeeslog">
                <li class="dropdown">
                  <a class="nav-link text-white"
                    ><i class="fas fa-pen font-20"></i><span>Employee Report</span></a
                  >
                </li>
              </router-link>
              <router-link to="/logs">
                <li class="dropdown">
                  <a class="nav-link text-white"
                    ><i class="fas fa-pen font-20"></i><span>Visitor Report</span></a
                  >
                </li>
              </router-link>
            </div>
          </ul>
        </aside>
      </div>
      <!-- Main Content -->
      <div class="main-content">
        <section class="section">
          <div class="section-body">
            <!-- add content here -->
            <router-view />
          </div>
        </section>
        <div class="settingSidebar">
          <a href="javascript:void(0)" class="settingPanelToggle">
            <i class="fa fa-spin fa-cog"></i>
          </a>
          <div class="settingSidebar-body ps-container ps-theme-default">
            <div class="fade show active">
              <div class="setting-panel-header">Setting Panel</div>
              <div class="p-15 border-bottom">
                <h6 class="font-medium m-b-10">Select Layout</h6>
                <div class="selectgroup layout-color w-50">
                  <label class="selectgroup-item">
                    <input
                      type="radio"
                      name="value"
                      value="1"
                      class="selectgroup-input-radio select-layout"
                      checked
                    />
                    <span class="selectgroup-button">Light</span>
                  </label>
                  <label class="selectgroup-item">
                    <input
                      type="radio"
                      name="value"
                      value="2"
                      class="selectgroup-input-radio select-layout"
                    />
                    <span class="selectgroup-button">Dark</span>
                  </label>
                </div>
              </div>
              <div class="p-15 border-bottom">
                <h6 class="font-medium m-b-10">Sidebar Color</h6>
                <div class="selectgroup selectgroup-pills sidebar-color">
                  <label class="selectgroup-item">
                    <input
                      type="radio"
                      name="icon-input"
                      value="1"
                      class="selectgroup-input select-sidebar"
                    />
                    <span
                      class="selectgroup-button selectgroup-button-icon"
                      data-toggle="tooltip"
                      data-original-title="Light Sidebar"
                      ><i class="fas fa-sun"></i
                    ></span>
                  </label>
                  <label class="selectgroup-item">
                    <input
                      type="radio"
                      name="icon-input"
                      value="2"
                      class="selectgroup-input select-sidebar"
                      checked
                    />
                    <span
                      class="selectgroup-button selectgroup-button-icon"
                      data-toggle="tooltip"
                      data-original-title="Dark Sidebar"
                      ><i class="fas fa-moon"></i
                    ></span>
                  </label>
                </div>
              </div>
              <div class="p-15 border-bottom">
                <h6 class="font-medium m-b-10">Color Theme</h6>
                <div class="theme-setting-options">
                  <ul class="choose-theme list-unstyled mb-0">
                    <li title="white" class="active">
                      <div class="white"></div>
                    </li>
                    <li title="cyan">
                      <div class="cyan"></div>
                    </li>
                    <li title="black">
                      <div class="black"></div>
                    </li>
                    <li title="purple">
                      <div class="purple"></div>
                    </li>
                    <li title="orange">
                      <div class="orange"></div>
                    </li>
                    <li title="green">
                      <div class="green"></div>
                    </li>
                    <li title="red">
                      <div class="red"></div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="p-15 border-bottom">
                <div class="theme-setting-options">
                  <label class="m-b-0">
                    <input
                      type="checkbox"
                      name="custom-switch-checkbox"
                      class="custom-switch-input"
                      id="mini_sidebar_setting"
                    />
                    <span class="custom-switch-indicator"></span>
                    <span class="control-label p-l-10">Mini Sidebar</span>
                  </label>
                </div>
              </div>
              <div class="p-15 border-bottom">
                <div class="theme-setting-options">
                  <label class="m-b-0">
                    <input
                      type="checkbox"
                      name="custom-switch-checkbox"
                      class="custom-switch-input"
                      id="sticky_header_setting"
                    />nav-link
                    <span class="custom-switch-indicator"></span>
                    <span class="control-label p-l-10">Sticky Header</span>
                  </label>
                </div>
              </div>
              <div class="mt-4 mb-4 p-3 align-center rt-sidebar-last-ele">
                <a href="#" class="btn btn-icon icon-left btn-primary btn-restore-theme">
                  <i class="fas fa-undo"></i> Restore Default
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="main-footer">
        <div class="footer-left">
          <a href="wowlogbook.com">wowlogbook</a>
        </div>
        <div class="footer-right"></div>
      </footer>
    </div>
  </div>
</template>

<script>
import axiosClient from "@/axios";
import store from "@/store";
import toast from "./Toast.vue";
import ChangePassword from "@/components/ChangePassword.vue";

export default {
  data() {
    return {};
  },
  mounted() {
    if (!sessionStorage.getItem("token")) {
      this.$router.push("/");
    }

    var dropmenu = document.getElementById('emps_menu');
    dropmenu.style.display = 'none';

    document.getElementById('empsdrop').addEventListener('click',function(){
      if(dropmenu.style.display == 'none'){
        dropmenu.style.display = 'block';
      }else{
        dropmenu.style.display = 'none';

      }
    })
  },
  methods: {
    logout() {
      // axiosClient.post('logout').then(()=>{

      // })
      sessionStorage.clear();
      this.$router.push("/");
    },
    user() {
      return sessionStorage.getItem("role");
    },
    changepass() {
      toast(
        {
          component: ChangePassword,
          listeners: {
            changed: () => {
              toast.success("Password changed");
            },
          },
        },
        { timeout: false, icon: false, closeOnClick: false }
      );
    },
  },
};
</script>

<style>
.main-sidebar {
  background-image: url("../loginimg.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.692) !important;
}

.side {
  background-color: rgba(20, 8, 192, 0.781);
}
</style>
