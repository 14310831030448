<template>
    <div class="d-flex">
        <div class="">
            <label for="checkin" class="font-19">Check in image</label>
            <img :src="baseimg+checkinImg" style="width:200px;" alt="" srcset="">
        </div>
        <div class="ml-4">
            <label for="checkin" class="font-19">Check out image</label>
            <img :src="baseimg+checkoutImg" style="width:200px;" alt="" srcset="">
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                // baseimg: 'http://localhost:8000/employee/'
                baseimg: 'http://172.161.150.8/employee/'
                // baseimg: 'https://api.abdavid.wowlogbook.com/employee/'
            }
        },
        props:{
            checkinImg:'',
            checkoutImg:''
        }
    }
</script>

<style lang="scss" scoped>

</style>