<template>
    <div>
        <form @submit.prevent="addmeet" method="post">
            <div class="row">
                <div class="form-group mr-2">
                <label for="" class="text-white">Name</label>
                <input v-model="staff.name" type="text" class="form-control" />
                <!-- <span class="text-danger">Ieeror</span> -->
            </div>
            <div class="form-group">
                <label for="" class="text-white">Phone number</label>
                <input v-model="staff.phone" type="text" class="form-control" />
            </div> 
            </div>
           <div class="row">
            <div class="form-group mr-2">
                <label for="" class="text-white">Email</label>
                <input
                    v-model="staff.email"
                    type="email"
                    class="form-control"
                />
            </div>
            <div class="form-group">
                <label for="" class="text-white">Username</label>
                <input
                    v-model="staff.username"
                    type="text"
                    class="form-control"
                />
            </div>
           </div>
            <div class="row">
                 <div class="form-group mr-2">
                <label for="" class="text-white">Staff type</label>
                <select v-model="staff.type" id="" class="form-control">
                    <option value="">select staff type</option>
                    <option value="employee" >Employee</option>
                    <option value="intern" >Intern</option>
                </select>
            </div>
            <div class="form-group">
                <label for="" class="text-white">Contact person for visitor checkin</label>
                <select v-model="staff.position" id="" class="form-control">
                    <option value="">select role</option>
                    <option >None</option>
                    <option >Repairs</option>
                    <option >Interview</option>
                </select>
            </div>
            </div>
           
            <button type="submit" class="btn btn-secondary text-dark">
                {{ employee != null ? "Update" : "Save" }}
            </button>
        </form>
    </div>
</template>

<script>
import axiosClient from "@/axios";
import toast from "./Toast.vue";

export default {
    data() {
        return {
            staff: {},
            departments:{}
        };
    },
    props: {
        employee: {},
    },
    mounted() {
        if (this.employee) {
            this.staff = this.employee;
        }
        this.getDepts();
    },
    methods: {
        getDepts() {
            axiosClient.get("departments").then(({ data }) => {
        // console.log(data.data);        
                this.departments = data.data;
            });
        },
        addmeet() {
            if (!this.employee) {
                axiosClient
                    .post("add-employee", this.staff)
                    .then(({ data }) => {
                        toast.clear();
                        toast.success(data.message);
                        this.$emit("done");
                    })
                    .catch(({ response }) => {
                        console.log(response.data.errors);
                        toast.error("All Fields are required");
                    });
            } else {
                axiosClient
                    .post("update-employee", this.staff)
                    .then(({ data }) => {
                        toast.clear();
                        toast.success(data.message);
                        this.$emit("done");
                    })
                    .catch(({ response }) => {
                        console.log(response.data.errors);
                        toast.error("All Fields are required");
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
