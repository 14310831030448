<template>
    <div>
        <h3>Departments</h3>
        <card-component>
            <a @click="adddept" class="btn btn-primary btn-sm text-white mb-3">Add Department</a>
              <table-layout>
            <template #head>
                <th>#</th>
                <th>Name</th>
              <th>Actions</th>
            </template>
            <tr v-for="(employee, index) in dept" :key="employee">
                <td>{{ index+1 }}</td>
                <td>{{ employee.text }}</td>
            <td><a @click="editstaff(employee)" class="btn btn-secondary">Edit</a> | <a @click="delstaff(employee.id)" class="btn btn-danger">Delete</a></td>
            </tr>
        </table-layout>
        </card-component>
      
    </div>
</template>

<script>
import CardComponent from '../components/CardComponent.vue'
import TableLayout from '../components/TableLayout.vue'
import axiosClient from '@/axios'
import moment from 'moment'
import toast from "@/components/Toast.vue"
import AddStaff from '@/components/AddStaff.vue'
import AddDept from '@/components/AddDept.vue'


    export default {
  components: { TableLayout, CardComponent },
  data(){
    return {
        dept:'',
    }
  },
  mounted(){
   this.getDepts();
  },
  methods:{
    getDepts(){
      axiosClient.get('departments').then(({data})=>{
        this.dept = data.data;
    })
    },
    timeformat(time){
        if(time){
            return moment(time).format('h:m a');
        }
    },
    dateformat(date){
       return moment(date).format('ddd, Do MMM Y');
    },
    adddept(){
      toast.clear();
      toast({component: AddDept, listeners:{done: ()=>{this.getDepts()}}},
      {timeout:false, icon:false, closeOnClick:false});
    },
    editstaff(dept){
      toast.clear();
      toast({component: AddDept,props:{department:{name:dept.text, dept: dept.id}}, listeners:{done: ()=>{this.getDepts()}}},
      {timeout:false, icon:false, closeOnClick:false});
    },
    delstaff(id){
      if(confirm('Confirm department delete')){
        axiosClient.post('delete-dept', {mask: id}).then(({data})=>{
          toast.success(data.message);
          this.getDepts();
        })
      }
}
  }
        
    }
</script>

<style lang="scss" scoped>

</style>