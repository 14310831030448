<template>
    <div class="">
        <section class="section">
            <div class="row">
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="card" style="background-color: #263544">
                        <div class="card-statistic-4">
                            <div
                                class="align-items-center justify-content-between"
                            >
                                <div class="row">
                                    <div
                                        class="col-lg-8 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3"
                                    >
                                        <div class="card-content text-white">
                                            <h5 class="font-21">
                                                Employees Present
                                            </h5>
                                            <h2 class="mb-3 font-28">
                                                {{ attendance.present }}
                                            </h2>
                                            <p class="mb-0">
                                                <!-- <span class="col-green">10%</span> Increase -->
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        class="col-lg-4 col-md-6 col-sm-6 col-xs-4 pl-0"
                                    >
                                        <div class="banner-img">
                                            <img
                                                src="assets/img/banner/1.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="card" style="background-color: #263544">
                        <div class="card-statistic-4">
                            <div
                                class="align-items-center justify-content-between"
                            >
                                <div class="row">
                                    <div
                                        class="col-lg-8 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3"
                                    >
                                        <div class="card-content text-white">
                                            <h5 class="font-21">
                                                Employees Absent
                                            </h5>
                                            <h2 class="mb-3 font-28">
                                                {{ attendance.absent }}
                                            </h2>
                                            <p class="mb-0">
                                                <!-- <span class="col-orange">09%</span> Decrease -->
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        class="col-lg-4 col-md-6 col-sm-6 col-xs-6 pl-0"
                                    >
                                        <div class="banner-img">
                                            <img
                                                src="assets/img/banner/2.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="card" style="background-color: #263544">
                        <div class="card-statistic-4">
                            <div
                                class="align-items-center justify-content-between"
                            >
                                <div class="row">
                                    <div
                                        class="col-lg-8 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3"
                                    >
                                        <div class="card-content text-white">
                                            <h5 class="font-21">
                                                Ongoing Visits
                                            </h5>
                                            <h2 class="mb-3 font-28">{{stat.ongoing}}</h2>
                                            <p class="mb-0">
                                                <!-- <span class="col-green">18%</span> Increase -->
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        class="col-lg-4 col-md-6 col-sm-6 col-xs-6 pl-0"
                                    >
                                        <div class="banner-img">
                                            <img
                                                src="assets/img/banner/3.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="card" style="background-color: #263544">
                        <div class="card-statistic-4">
                            <div
                                class="align-items-center justify-content-between"
                            >
                                <div class="row">
                                    <div
                                        class="col-lg-8 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3"
                                    >
                                        <div class="card-content text-white">
                                            <h5 class="font-21">
                                                Ongoing meetings
                                            </h5>
                                            <h2 class="mb-3 font-28">
                                                {{stat.meetings}}
                                            </h2>
                                            <p class="mb-0">
                                                <!-- <span class="col-green">42%</span> Increase -->
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        class="col-lg-4 col-md-6 col-sm-6 col-xs-6 pl-0"
                                    >
                                        <div class="banner-img">
                                            <img
                                                src="assets/img/banner/4.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <h4>Yearly Visit chart</h4>
                            <div class="card-header-action">
                                <!-- <div class="dropdown">
                                    <a
                                        href="#"
                                        data-toggle="dropdown"
                                        class="btn btn-warning dropdown-toggle"
                                        >Options</a
                                    >
                                    <div class="dropdown-menu">
                                        <a
                                            href="#"
                                            class="dropdown-item has-icon"
                                            ><i class="fas fa-eye"></i> View</a
                                        >
                                        <a
                                            href="#"
                                            class="dropdown-item has-icon"
                                            ><i class="far fa-edit"></i> Edit</a
                                        >
                                        <div class="dropdown-divider"></div>
                                        <a
                                            href="#"
                                            class="dropdown-item has-icon text-danger"
                                            ><i class="far fa-trash-alt"></i>
                                            Delete</a
                                        >
                                    </div>
                                </div> -->
                                <!-- <a href="#" class="btn btn-primary">View All</a> -->
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div id="chart1"></div>
                                    <div class="row mb-0">
                                        <div
                                            class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
                                        >
                                            <div
                                                class="list-inline text-center"
                                            ></div>
                                        </div>
                                        <div
                                            class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
                                        >
                                            <div
                                                class="list-inline text-center"
                                            ></div>
                                        </div>
                                        <div
                                            class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
                                        >
                                            <div
                                                class="list-inline text-center"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <h4>Visitor Checkin Chart</h4>
                        </div>
                        <div class="card-body">
                            <div id="chart4" class="chartsh"></div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 col-sm-12 col-lg-4">
                    <div class="card">
                        <div class="card-header">
                            <h4>Chart</h4>
                        </div>
                        <div class="card-body">
                            <div class="summary">
                                <div
                                    class="summary-chart active"
                                    data-tab-group="summary-tab"
                                    id="summary-chart"
                                >
                                    <div id="chart3" class="chartsh"></div>
                                </div>
                                <div
                                    data-tab-group="summary-tab"
                                    id="summary-text"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div> -->
                
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <h4>Employee Attendance Chart</h4>
                        </div>
                        <div class="card-body">
                            <div id="chart2" class="chartsh"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="settingSidebar">
            <a href="javascript:void(0)" class="settingPanelToggle">
                <i class="fa fa-spin fa-cog"></i>
            </a>
            <div class="settingSidebar-body ps-container ps-theme-default">
                <div class="fade show active">
                    <div class="setting-panel-header">Setting Panel</div>
                    <div class="p-15 border-bottom">
                        <h6 class="font-medium m-b-10">Select Layout</h6>
                        <div class="selectgroup layout-color w-50">
                            <label class="selectgroup-item">
                                <input
                                    type="radio"
                                    name="value"
                                    value="1"
                                    class="selectgroup-input-radio select-layout"
                                    checked
                                />
                                <span class="selectgroup-button">Light</span>
                            </label>
                            <label class="selectgroup-item">
                                <input
                                    type="radio"
                                    name="value"
                                    value="2"
                                    class="selectgroup-input-radio select-layout"
                                />
                                <span class="selectgroup-button">Dark</span>
                            </label>
                        </div>
                    </div>
                    <div class="p-15 border-bottom">
                        <h6 class="font-medium m-b-10">Sidebar Color</h6>
                        <div
                            class="selectgroup selectgroup-pills sidebar-color"
                        >
                            <label class="selectgroup-item">
                                <input
                                    type="radio"
                                    name="icon-input"
                                    value="1"
                                    class="selectgroup-input select-sidebar"
                                />
                                <span
                                    class="selectgroup-button selectgroup-button-icon"
                                    data-toggle="tooltip"
                                    data-original-title="Light Sidebar"
                                    ><i class="fas fa-sun"></i
                                ></span>
                            </label>
                            <label class="selectgroup-item">
                                <input
                                    type="radio"
                                    name="icon-input"
                                    value="2"
                                    class="selectgroup-input select-sidebar"
                                    checked
                                />
                                <span
                                    class="selectgroup-button selectgroup-button-icon"
                                    data-toggle="tooltip"
                                    data-original-title="Dark Sidebar"
                                    ><i class="fas fa-moon"></i
                                ></span>
                            </label>
                        </div>
                    </div>
                    <div class="p-15 border-bottom">
                        <h6 class="font-medium m-b-10">Color Theme</h6>
                        <div class="theme-setting-options">
                            <ul class="choose-theme list-unstyled mb-0">
                                <li title="white" class="active">
                                    <div class="white"></div>
                                </li>
                                <li title="cyan">
                                    <div class="cyan"></div>
                                </li>
                                <li title="black">
                                    <div class="black"></div>
                                </li>
                                <li title="purple">
                                    <div class="purple"></div>
                                </li>
                                <li title="orange">
                                    <div class="orange"></div>
                                </li>
                                <li title="green">
                                    <div class="green"></div>
                                </li>
                                <li title="red">
                                    <div class="red"></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="p-15 border-bottom">
                        <div class="theme-setting-options">
                            <label class="m-b-0">
                                <input
                                    type="checkbox"
                                    name="custom-switch-checkbox"
                                    class="custom-switch-input"
                                    id="mini_sidebar_setting"
                                />
                                <span class="custom-switch-indicator"></span>
                                <span class="control-label p-l-10"
                                    >Mini Sidebar</span
                                >
                            </label>
                        </div>
                    </div>
                    <div class="p-15 border-bottom">
                        <div class="theme-setting-options">
                            <label class="m-b-0">
                                <input
                                    type="checkbox"
                                    name="custom-switch-checkbox"
                                    class="custom-switch-input"
                                    id="sticky_header_setting"
                                />
                                <span class="custom-switch-indicator"></span>
                                <span class="control-label p-l-10"
                                    >Sticky Header</span
                                >
                            </label>
                        </div>
                    </div>
                    <div class="mt-4 mb-4 p-3 align-center rt-sidebar-last-ele">
                        <a
                            href="#"
                            class="btn btn-icon icon-left btn-primary btn-restore-theme"
                        >
                            <i class="fas fa-undo"></i> Restore Default
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  // import '../../public/assets/js/page/index'
  import axiosClient from '@/axios';

 export default {
  data(){
    return{
      attendance:{},
      stat:{}

    }
  },
  mounted(){
    this.getDashStat();
    this.getAttendance();
   
  },
  methods:{
    getAttendance(){
      axiosClient.get('employee/attendance').then(({data})=>{
        this.attendance = data.data;
      }).catch(({response})=>{console.log(response)});
    },
    getDashStat(){
      axiosClient.get('admin/dash').then(({data})=>{
        this.stat = data.data;
        this.visit(data.data.visits);
        this.EmployeeCheckin(data.data.emp_attendance);
        this.VisitorCheckin(data.data.visit_attendance);
      }).catch(({response})=>{response});
    },
    visit(visit) {
    var options = {
        chart: {
            height: 230,
            type: "bar",
            shadow: {
                enabled: true,
                color: "#000",
                top: 18,
                left: 7,
                blur: 10,
                opacity: 1
            },
            toolbar: {
                show: false
            }
        },
        colors: ["#786BED", "#999b9c"],
        dataLabels: {
            enabled: true
        },
        stroke: {
            curve: "smooth"
        },
        series: [
        {
            name: "Number of visits",
            data: visit
        }
        ],
        grid: {
            borderColor: "#e7e7e7",
            row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.0
            }
        },
        markers: {
            size: 6
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct","Nov","Dec"],

            labels: {
                style: {
                    colors: "#9aa0ac"
                }
            }
        },
        yaxis: {
            title: {
                text: "Number of visits"
            },
            labels: {
                style: {
                    color: "#9aa0ac"
                }
            },
            min: 5,
            max: 40
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            offsetY: -25,
            offsetX: -5
        }
    };

    var chart = new ApexCharts(document.querySelector("#chart1"), options);

    chart.render();
},

EmployeeCheckin(data) {
    var options = {
        chart: {
            height: 250,
            type: 'bar',
            stacked: false,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: true
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: false,
                
            },
        },
        series: [ {
            name: 'PRODUCT C',
            data: data
        }],
        xaxis: {
            type: 'text',
            categories:  [
            '0:00',
            '1:00',
            '2:00',
            '3:00',
            '4:00',
            '5:00',
            '6:00',
            '7:00',
            '8:00',
            '9:00',
            '10:00',
            '11:00',
            '12:00',
            '13:00',
            '14:00',
            '15:00',
            '16:00',
            '17:00',
            '18:00',
            '19:00',
            '20:00',
            '21:00',
            '22:00',
            '23:00',
        ],
            labels: {
                style: {
                    colors: "#9aa0ac"
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    color: "#9aa0ac"
                }
            }
        },
        legend: {
            position: 'top',
            offsetY: 40,
            show: false,
        },
        fill: {
            opacity: 1
        },
    }

    var chart = new ApexCharts(
        document.querySelector("#chart2"),
        options
    );

    chart.render();

},
VisitorCheckin(data) {
    var options = {
        chart: {
            height: 250,
            type: 'bar',
            stacked: false,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: true
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50px',
            },
        },
        series: [ {
            name: 'PRODUCT C',
            data: data
        }],
        xaxis: {
            type: 'text',
            categories:  [
            '0:00',
            '1:00',
            '2:00',
            '3:00',
            '4:00',
            '5:00',
            '6:00',
            '7:00',
            '8:00',
            '9:00',
            '10:00',
            '11:00',
            '12:00',
            '13:00',
            '14:00',
            '15:00',
            '16:00',
            '17:00',
            '18:00',
            '19:00',
            '20:00',
            '21:00',
            '22:00',
            '23:00',
        ],
            labels: {
                style: {
                    colors: "#9aa0ac"
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    color: "#9aa0ac"
                }
            }
        },
        legend: {
            position: 'top',
            offsetY: 40,
            show: false,
        },
        fill: {
            opacity: 1
        },
    }


    var chart = new ApexCharts(
        document.querySelector("#chart4"),
        options
    );

    chart.render();

},
  }
 }
</script>
