<template>
    <div>
<form @submit.prevent="addmeet" method="post">
         <div class="form-group row">
        <label for="" class="text-white">Meeting name</label>
        <input v-model="meeting.name" type="text" class="form-control" />
        <!-- <span class="text-danger">Ieeror</span> -->
    </div>
    <div class="row">
    <div class="form-group mr-2">
        <label for="" class="text-white">Meeting date and time</label>
        <input v-model="meeting.date" type="dateTime-local" class="form-control" />
    </div>
    <div class="form-group">
        <label for="" class="text-white">Meeting end time</label>
        <input v-model="meeting.end" type="time" class="form-control" />
    </div>
    </div>
    <div class="form-group row">
        <label for="" class="text-white">Meeting room</label>
        <input v-model="meeting.room" type="text" class="form-control" />
    </div>
   <!-- <div class="row justify-content-between"> -->
    <div class="form-group row">
        <label for="" class="text-white">Host</label>
        <input v-model="meeting.host" type="text" class="form-control" />
        <!-- <Select2 :options="emps" :settings="{multiple: true}" @select="selected($event)" class="mb-4" /> -->
    </div>
    <div class="form-group row">
        <label for="" class="text-white">Participants (seperate participants with ",")</label>
        <textarea v-model="meeting.participants" class="form-control" />
        <!-- <Select2 :options="emps" :settings="{multiple: true}" @select="selected($event)" class="mb-4" /> -->
    </div>
   <!-- </div> -->
    
    
   
    <button type="submit" class="btn btn-secondary text-dark">{{emeeting != null ? "Update" : "Save"}}</button>
</form>
    </div>
</template>

<script>
import axiosClient from '@/axios';
import toast from './Toast.vue';
import moment from 'moment';
import Select2 from 'vue3-select2-component';

    export default {
        data(){
            return {
                meeting:{},
                emps:[]
            }
        },
        props:{
            emeeting: {}
        },
        components:{
            Select2
        },
        methods:{
            selected(picked){
                console.log(picked);
            },
            addmeet(){
                if(this.emeeting){
                    // console.log(this.meeting.end)
                    axiosClient.post('update-meeting', this.meeting).then(({data})=>{
                    toast.clear();
                    toast.success(data.message);
                    this.$emit('done');
                }).catch(({response})=>{
                    console.log(response.data.errors)
                    toast.error("All Fields are required");
                })
                }else{
                    axiosClient.post('add-meeting', this.meeting).then(({data})=>{
                    toast.clear();
                    toast.success(data.message);
                    this.$emit('done');
                }).catch(({response})=>{
                    console.log(response.data.errors)
                    toast.error("All Fields are required");
                })
                }   
            },
            getEmployees(){
                axiosClient.get('employee').then(({data})=>{
                    data.data.forEach((element,val) => {
                        var val = {};
                        val['id'] = element.mask;
                        val['text'] = element.name;
                        this.emps.push(val);
                    });
                    // console.log(this.emps);
                    // this.emps = data.data;
                })
            }
        },
        mounted(){
            if(this.emeeting){
                this.meeting = this.emeeting;
                this.meeting.end = moment(this.emeeting.updated_at).format('H:mm');
            }

            this.getEmployees();
        }
        
    }
</script>

<style>
.select2-selection--multiple{
    width: 250px!important;
}
</style>