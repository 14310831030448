<template>
    <div>
        <h3>Send SMS</h3>
        <CardLayout class="d-grid col-lg-6">
            <div class="col-sm-12">
               <div >
                <SwitchComponent @switched="swit" :whom="to" class="mb-2"/>
                <div class="" v-show="singles">
                    <label for="">Select departments</label>
                <Select2 :options="attendants" :settings="{multiple: true}" @select="selected($event)" @change="changed($event)" class="mb-4" />
                </div>
                <textarea v-model="message" id=""  class="form-control col-sm-12" cols="30" rows="20" placeholder="Write message here...."></textarea>
           
            </div> 
            </div>
            <a @click="sendSms" class="btn btn-primary text-white col-lg-3 mt-3 ml-3" >Send message</a>
            
        </CardLayout>
    </div>
</template>

<script>
import CardLayout from "@/components/CardComponent.vue";
import Select2 from "vue3-select2-component";
import axiosClient from "@/axios";
import SwitchComponent from "@/components/SwitchComponent.vue";
import toast from "@/components/Toast.vue";

export default {
    components:{
        CardLayout,
        Select2,
        SwitchComponent
    },
    data(){
        return {
            group: [],
            message: '',
            choose: '',
            attendants: [],
            to:'All departments',
            singles: false
        }
    },
    methods:{
        selected(chosen){
            this.group.push(chosen.text);
            // this.group[chosen.id] = chosen.text;
            // console.log(chosen.id);
        },
        changed(chosen){
            console.log("changed");
            console.log(chosen);
        },
        sendSms(){
            if(this.to == 'All departments'){
                axiosClient.post('sms-blast',{to: 'all', message: this.message}).then(({data})=>{
                    toast.success(data.message);
                    }).catch(({response})=>{console.log(response.data)});
            }else{
                axiosClient.post('sms-blast',{to: this.group, message: this.message}).then(({data})=>{
                    toast.success(data.message);
                }).catch(({response})=>{console.log(response.data)});
            }
        },
        swit(status){
            if(!status){
                this.to= 'All departments';
                this.singles= false;

            }else{
                this.to = 'Selected departments';
                this.singles= true;
            }
        }
    },
    mounted(){
        axiosClient.get('departments').then(({data})=>{
            this.attendants = data.data;
        }).catch(({response})=>{
            console.log(response);
        })
    }
}
</script>

<style>
.selection{
    width: 730px;
    display: grid;
}
</style>
