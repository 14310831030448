<template>
    <div>
        <h4 class="text-white">View history</h4>
        <form @submit.prevent="lookup" method="post">
            <div class="form-group">
                <label for="" class="text-white">History from</label>
                <input type="date" v-model="history.from" id="" class="form-control">
                </div>
                <div class="form-group">
                <label for="" class="text-white">History to</label>
                <input type="date" v-model="history.to" id="" class="form-control">
                </div>
                <button class="btn btn-success" type="submit">Display</button>
        </form>
    </div>
</template>

<script>
import axiosClient from '@/axios';
export default {
   data(){
    return {
        history:{}
    }
   },
   props:{
    which: '',
    employee: ''
   },
   methods:{
    lookup(){
        this.history.employee = this.employee;
        axiosClient.post(this.which+'/history',this.history).then(({data})=>{
            this.$emit('viewReport', data.data);
        }).catch(({response})=>{
            console.log(response.data);
        })
    }
   }
}
</script>

<style lang="scss" scoped>

</style>