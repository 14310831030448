<template>
  <table class="table">
    <thead>
      <tr>
      <slot name="head" />
      </tr>
    </thead>
    <tbody>
      <slot />
    </tbody>
  </table>
</template>
