<template>
    <div class="" v-if="type">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="300.000000pt" height="90.000000pt" viewBox="0 0 300.000000 190.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.10, written by Peter Selinger 2001-2011
</metadata>
<g transform="translate(-70.000000,120.000000) scale(0.100000,-0.100000)"
fill="#000" stroke="none">
<path d="M1089 957 c-32 -22 -34 -26 -34 -84 0 -52 -4 -66 -25 -88 -19 -21
-25 -37 -24 -72 1 -62 39 -99 116 -114 51 -10 61 -9 99 9 38 19 45 20 70 6 15
-7 43 -14 61 -14 l33 1 -35 15 c-52 23 -71 43 -60 65 6 10 10 38 10 63 0 31 5
48 16 54 11 6 -1 9 -40 9 -60 0 -70 -6 -33 -22 18 -8 23 -19 25 -57 2 -27 -1
-48 -6 -48 -5 0 -45 35 -89 78 -89 85 -107 124 -79 167 33 51 111 41 122 -15
9 -41 24 -38 24 6 0 31 -4 37 -35 50 -48 20 -77 18 -116 -9z m124 -284 c33
-26 30 -37 -17 -53 -83 -27 -162 32 -152 113 8 71 22 74 85 14 31 -29 69 -62
84 -74z"/>
<path d="M2775 973 c-36 -5 -58 -23 -28 -23 17 0 21 -8 26 -57 3 -32 5 -59 3
-60 -1 -2 -17 2 -34 8 -27 9 -39 7 -76 -10 -57 -28 -77 -51 -83 -98 -7 -57 18
-110 61 -128 48 -20 60 -19 94 10 36 30 45 31 37 5 -6 -18 -2 -20 45 -20 32 0
49 4 45 10 -3 6 -15 10 -26 10 -18 0 -19 8 -19 180 0 99 -3 179 -7 179 -5 -1
-21 -4 -38 -6z m-23 -169 c30 -16 31 -17 25 -76 -6 -71 -26 -98 -73 -98 -44 0
-74 38 -74 95 0 81 53 115 122 79z"/>
<path d="M320 962 c0 -5 -32 -82 -72 -171 -55 -126 -76 -165 -95 -172 -38 -14
-27 -19 42 -19 37 0 65 4 65 10 0 6 -12 10 -26 10 -17 0 -24 5 -21 13 2 6 10
29 18 50 l13 37 76 0 75 0 18 -43 c22 -55 22 -57 -8 -57 -14 0 -25 -4 -25 -10
0 -6 32 -10 75 -10 77 0 91 5 53 19 -16 6 -40 50 -94 177 -40 92 -77 170 -83
172 -6 2 -11 -1 -11 -6z m33 -140 c15 -38 27 -71 27 -75 0 -4 -27 -7 -61 -7
-33 0 -59 4 -57 8 2 4 14 38 27 75 14 37 27 67 30 67 3 0 18 -31 34 -68z"/>
<path d="M543 955 c2 -5 13 -11 25 -13 21 -3 22 -7 22 -154 0 -127 -3 -154
-17 -170 -15 -17 -13 -18 71 -18 57 0 97 5 116 15 89 46 94 160 7 183 l-32 8
30 15 c51 24 60 105 14 129 -26 14 -240 19 -236 5z m201 -31 c22 -21 20 -67
-3 -88 -11 -10 -38 -20 -60 -23 l-41 -6 0 67 0 66 44 0 c27 0 51 -6 60 -16z
m7 -153 c18 -14 24 -29 24 -61 0 -55 -37 -90 -96 -90 l-39 0 0 85 0 85 44 0
c28 0 52 -7 67 -19z"/>
<path d="M1450 957 c0 -4 12 -12 26 -17 l26 -10 -3 -149 c-3 -106 -8 -153 -18
-165 -12 -15 -5 -16 91 -16 120 0 177 21 215 79 68 102 43 227 -55 268 -38 16
-282 25 -282 10z m245 -31 c95 -40 103 -214 13 -279 -21 -15 -44 -21 -95 -21
l-68 -1 -3 145 c-1 79 0 150 2 157 7 18 107 17 151 -1z"/>
<path d="M2455 950 c-12 -20 6 -51 28 -48 31 4 31 52 0 56 -11 2 -24 -2 -28
-8z"/>
<path d="M1928 834 c-29 -12 -38 -22 -38 -40 0 -13 5 -24 10 -24 6 0 10 4 10
9 0 18 31 41 54 41 25 0 56 -28 56 -52 0 -10 -18 -19 -55 -27 -62 -14 -95 -42
-95 -80 0 -32 8 -45 37 -60 30 -15 35 -14 77 14 34 23 36 23 36 5 0 -17 7 -20
40 -20 42 0 54 14 19 23 -18 5 -20 12 -17 91 3 74 0 88 -17 105 -28 28 -73 34
-117 15z m92 -136 c0 -40 -25 -68 -61 -68 -55 0 -59 62 -5 86 51 22 66 18 66
-18z"/>
<path d="M2463 836 c-44 -10 -58 -26 -23 -26 19 0 20 -7 20 -95 0 -88 -1 -95
-20 -95 -11 0 -20 -4 -20 -10 0 -6 28 -10 65 -10 37 0 65 4 65 10 0 6 -9 10
-20 10 -18 0 -20 8 -22 113 l-3 112 -42 -9z"/>
<path d="M2120 830 c0 -5 6 -10 14 -10 9 0 33 -45 63 -115 26 -63 53 -115 59
-115 6 0 31 46 55 103 24 56 55 112 68 125 l24 22 -59 0 c-53 0 -66 -12 -23
-22 15 -3 13 -12 -13 -78 -16 -41 -33 -79 -37 -83 -4 -4 -21 27 -37 70 -16 42
-30 81 -32 85 -2 4 6 8 17 8 12 0 21 5 21 10 0 6 -27 10 -60 10 -33 0 -60 -4
-60 -10z"/>
<path d="M230 490 c0 -7 430 -10 1270 -10 840 0 1270 3 1270 10 0 7 -430 10
-1270 10 -840 0 -1270 -3 -1270 -10z"/>
<path d="M260 366 c-30 -79 -54 -128 -69 -136 -12 -7 -8 -9 19 -9 19 0 30 3
24 5 -7 3 -10 14 -7 24 4 15 14 20 39 20 37 0 58 -25 33 -41 -10 -6 -1 -9 25
-9 37 0 38 1 24 18 -9 9 -28 46 -43 82 -15 36 -29 67 -31 69 -2 2 -8 -8 -14
-23z m20 -35 c0 -5 3 -16 6 -25 4 -12 0 -16 -16 -16 -16 0 -20 4 -16 16 3 9 6
20 6 25 0 5 5 9 10 9 6 0 10 -4 10 -9z"/>
<path d="M720 378 c0 -5 5 -8 10 -8 15 0 13 -137 -2 -143 -7 -3 5 -6 27 -6 32
0 36 2 23 10 -26 15 -24 69 2 69 10 0 24 -8 29 -17 8 -15 10 -11 10 17 0 19
-3 29 -6 23 -2 -7 -16 -13 -29 -13 -20 0 -24 5 -24 30 0 24 4 30 21 30 12 0
28 -8 35 -17 12 -17 13 -16 14 4 0 19 -6 22 -55 25 -30 1 -55 0 -55 -4z"/>
<path d="M1193 378 c-2 -5 3 -8 11 -8 25 0 23 -125 -1 -139 -15 -8 -10 -10 22
-11 30 0 36 3 27 12 -7 7 -12 39 -12 76 0 63 0 63 28 60 34 -4 38 -38 5 -52
l-21 -10 29 -43 c19 -27 38 -43 51 -43 18 0 16 5 -16 38 -36 36 -36 37 -16 51
21 14 27 55 9 67 -15 9 -110 11 -116 2z"/>
<path d="M1700 378 c0 -4 5 -8 10 -8 15 0 13 -137 -2 -143 -7 -3 5 -5 27 -5
22 0 34 2 28 5 -16 6 -18 143 -3 143 6 0 10 4 10 8 0 4 -16 8 -35 8 -19 0 -35
-4 -35 -8z"/>
<path d="M2174 371 c-53 -33 -53 -112 0 -140 26 -14 98 -11 103 4 2 7 -13 9
-37 7 -29 -3 -47 1 -60 13 -25 23 -26 76 -2 98 24 22 82 23 82 2 0 -8 5 -15
10 -15 20 0 10 38 -12 44 -36 9 -51 7 -84 -13z"/>
<path d="M2694 314 c-15 -37 -34 -73 -42 -80 -11 -12 -8 -14 20 -14 21 0 28 3
20 8 -23 15 -2 42 33 42 35 0 56 -27 33 -42 -8 -5 2 -8 26 -8 33 0 37 2 24 13
-8 6 -28 42 -43 80 -15 37 -31 67 -35 67 -4 0 -20 -30 -36 -66z m45 6 c9 -28
8 -30 -14 -30 -22 0 -23 2 -14 30 6 17 12 30 14 30 2 0 8 -13 14 -30z"/>
</g>
</svg>
    </div>
    <div class="" v-else>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="300.000000pt" height="90.000000pt" viewBox="0 0 300.000000 190.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.10, written by Peter Selinger 2001-2011
</metadata>
<g transform="translate(-120.000000,120.000000) scale(0.100000,-0.100000)"
fill="#fff" stroke="none">
<path d="M1089 957 c-32 -22 -34 -26 -34 -84 0 -52 -4 -66 -25 -88 -19 -21
-25 -37 -24 -72 1 -62 39 -99 116 -114 51 -10 61 -9 99 9 38 19 45 20 70 6 15
-7 43 -14 61 -14 l33 1 -35 15 c-52 23 -71 43 -60 65 6 10 10 38 10 63 0 31 5
48 16 54 11 6 -1 9 -40 9 -60 0 -70 -6 -33 -22 18 -8 23 -19 25 -57 2 -27 -1
-48 -6 -48 -5 0 -45 35 -89 78 -89 85 -107 124 -79 167 33 51 111 41 122 -15
9 -41 24 -38 24 6 0 31 -4 37 -35 50 -48 20 -77 18 -116 -9z m124 -284 c33
-26 30 -37 -17 -53 -83 -27 -162 32 -152 113 8 71 22 74 85 14 31 -29 69 -62
84 -74z"/>
<path d="M2775 973 c-36 -5 -58 -23 -28 -23 17 0 21 -8 26 -57 3 -32 5 -59 3
-60 -1 -2 -17 2 -34 8 -27 9 -39 7 -76 -10 -57 -28 -77 -51 -83 -98 -7 -57 18
-110 61 -128 48 -20 60 -19 94 10 36 30 45 31 37 5 -6 -18 -2 -20 45 -20 32 0
49 4 45 10 -3 6 -15 10 -26 10 -18 0 -19 8 -19 180 0 99 -3 179 -7 179 -5 -1
-21 -4 -38 -6z m-23 -169 c30 -16 31 -17 25 -76 -6 -71 -26 -98 -73 -98 -44 0
-74 38 -74 95 0 81 53 115 122 79z"/>
<path d="M320 962 c0 -5 -32 -82 -72 -171 -55 -126 -76 -165 -95 -172 -38 -14
-27 -19 42 -19 37 0 65 4 65 10 0 6 -12 10 -26 10 -17 0 -24 5 -21 13 2 6 10
29 18 50 l13 37 76 0 75 0 18 -43 c22 -55 22 -57 -8 -57 -14 0 -25 -4 -25 -10
0 -6 32 -10 75 -10 77 0 91 5 53 19 -16 6 -40 50 -94 177 -40 92 -77 170 -83
172 -6 2 -11 -1 -11 -6z m33 -140 c15 -38 27 -71 27 -75 0 -4 -27 -7 -61 -7
-33 0 -59 4 -57 8 2 4 14 38 27 75 14 37 27 67 30 67 3 0 18 -31 34 -68z"/>
<path d="M543 955 c2 -5 13 -11 25 -13 21 -3 22 -7 22 -154 0 -127 -3 -154
-17 -170 -15 -17 -13 -18 71 -18 57 0 97 5 116 15 89 46 94 160 7 183 l-32 8
30 15 c51 24 60 105 14 129 -26 14 -240 19 -236 5z m201 -31 c22 -21 20 -67
-3 -88 -11 -10 -38 -20 -60 -23 l-41 -6 0 67 0 66 44 0 c27 0 51 -6 60 -16z
m7 -153 c18 -14 24 -29 24 -61 0 -55 -37 -90 -96 -90 l-39 0 0 85 0 85 44 0
c28 0 52 -7 67 -19z"/>
<path d="M1450 957 c0 -4 12 -12 26 -17 l26 -10 -3 -149 c-3 -106 -8 -153 -18
-165 -12 -15 -5 -16 91 -16 120 0 177 21 215 79 68 102 43 227 -55 268 -38 16
-282 25 -282 10z m245 -31 c95 -40 103 -214 13 -279 -21 -15 -44 -21 -95 -21
l-68 -1 -3 145 c-1 79 0 150 2 157 7 18 107 17 151 -1z"/>
<path d="M2455 950 c-12 -20 6 -51 28 -48 31 4 31 52 0 56 -11 2 -24 -2 -28
-8z"/>
<path d="M1928 834 c-29 -12 -38 -22 -38 -40 0 -13 5 -24 10 -24 6 0 10 4 10
9 0 18 31 41 54 41 25 0 56 -28 56 -52 0 -10 -18 -19 -55 -27 -62 -14 -95 -42
-95 -80 0 -32 8 -45 37 -60 30 -15 35 -14 77 14 34 23 36 23 36 5 0 -17 7 -20
40 -20 42 0 54 14 19 23 -18 5 -20 12 -17 91 3 74 0 88 -17 105 -28 28 -73 34
-117 15z m92 -136 c0 -40 -25 -68 -61 -68 -55 0 -59 62 -5 86 51 22 66 18 66
-18z"/>
<path d="M2463 836 c-44 -10 -58 -26 -23 -26 19 0 20 -7 20 -95 0 -88 -1 -95
-20 -95 -11 0 -20 -4 -20 -10 0 -6 28 -10 65 -10 37 0 65 4 65 10 0 6 -9 10
-20 10 -18 0 -20 8 -22 113 l-3 112 -42 -9z"/>
<path d="M2120 830 c0 -5 6 -10 14 -10 9 0 33 -45 63 -115 26 -63 53 -115 59
-115 6 0 31 46 55 103 24 56 55 112 68 125 l24 22 -59 0 c-53 0 -66 -12 -23
-22 15 -3 13 -12 -13 -78 -16 -41 -33 -79 -37 -83 -4 -4 -21 27 -37 70 -16 42
-30 81 -32 85 -2 4 6 8 17 8 12 0 21 5 21 10 0 6 -27 10 -60 10 -33 0 -60 -4
-60 -10z"/>
<path d="M230 490 c0 -7 430 -10 1270 -10 840 0 1270 3 1270 10 0 7 -430 10
-1270 10 -840 0 -1270 -3 -1270 -10z"/>
<path d="M260 366 c-30 -79 -54 -128 -69 -136 -12 -7 -8 -9 19 -9 19 0 30 3
24 5 -7 3 -10 14 -7 24 4 15 14 20 39 20 37 0 58 -25 33 -41 -10 -6 -1 -9 25
-9 37 0 38 1 24 18 -9 9 -28 46 -43 82 -15 36 -29 67 -31 69 -2 2 -8 -8 -14
-23z m20 -35 c0 -5 3 -16 6 -25 4 -12 0 -16 -16 -16 -16 0 -20 4 -16 16 3 9 6
20 6 25 0 5 5 9 10 9 6 0 10 -4 10 -9z"/>
<path d="M720 378 c0 -5 5 -8 10 -8 15 0 13 -137 -2 -143 -7 -3 5 -6 27 -6 32
0 36 2 23 10 -26 15 -24 69 2 69 10 0 24 -8 29 -17 8 -15 10 -11 10 17 0 19
-3 29 -6 23 -2 -7 -16 -13 -29 -13 -20 0 -24 5 -24 30 0 24 4 30 21 30 12 0
28 -8 35 -17 12 -17 13 -16 14 4 0 19 -6 22 -55 25 -30 1 -55 0 -55 -4z"/>
<path d="M1193 378 c-2 -5 3 -8 11 -8 25 0 23 -125 -1 -139 -15 -8 -10 -10 22
-11 30 0 36 3 27 12 -7 7 -12 39 -12 76 0 63 0 63 28 60 34 -4 38 -38 5 -52
l-21 -10 29 -43 c19 -27 38 -43 51 -43 18 0 16 5 -16 38 -36 36 -36 37 -16 51
21 14 27 55 9 67 -15 9 -110 11 -116 2z"/>
<path d="M1700 378 c0 -4 5 -8 10 -8 15 0 13 -137 -2 -143 -7 -3 5 -5 27 -5
22 0 34 2 28 5 -16 6 -18 143 -3 143 6 0 10 4 10 8 0 4 -16 8 -35 8 -19 0 -35
-4 -35 -8z"/>
<path d="M2174 371 c-53 -33 -53 -112 0 -140 26 -14 98 -11 103 4 2 7 -13 9
-37 7 -29 -3 -47 1 -60 13 -25 23 -26 76 -2 98 24 22 82 23 82 2 0 -8 5 -15
10 -15 20 0 10 38 -12 44 -36 9 -51 7 -84 -13z"/>
<path d="M2694 314 c-15 -37 -34 -73 -42 -80 -11 -12 -8 -14 20 -14 21 0 28 3
20 8 -23 15 -2 42 33 42 35 0 56 -27 33 -42 -8 -5 2 -8 26 -8 33 0 37 2 24 13
-8 6 -28 42 -43 80 -15 37 -31 67 -35 67 -4 0 -20 -30 -36 -66z m45 6 c9 -28
8 -30 -14 -30 -22 0 -23 2 -14 30 6 17 12 30 14 30 2 0 8 -13 14 -30z"/>
</g>
</svg>
    </div>


</template>
<script>

export default {
    props:{
        type: true
    }
}

</script>