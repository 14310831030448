<template>
    <div class="d-flex">
        <!-- Rectangular switch -->
        <!-- <label class="switch">
            <input type="checkbox" />
            <span class="slider"></span>
        </label> -->
        <!-- <input type="checkbox" v-model="status" v-on:change="checked($event)" /> -->

        <!-- Rounded switch -->
        <label class="switch mr-2">
            <input type="checkbox" v-model="status" v-on:change="checked($event)" />
            <span class="slider round"></span>
        </label>
       <strong class="switchfont">{{whom}}</strong> 
      
    </div>
</template>
<script>
export default {
    props:{
        whom: ''
    },
    data(){
        return {
           status: false
        }
    },
    methods:{
        checked(stat){
            this.$emit('switched', stat.target.checked);
            // console.log(stat.target.checked);
        }
    }
}
</script>

<style>
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(146, 148, 151, 0.822);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: rgb(236, 226, 226);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #696cff;
}

/* input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
} */

input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(26px);
    transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.switchfont{
    font-size: 20px;
    margin-left: 10px;
}
</style>
