import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AbdavidLogo from "@/components/AbdavidLogo.vue"
import TableLayout from "@/components/TableLayout.vue"
import CardComponent from './components/CardComponent.vue'
import moment from 'moment'
import Toast from 'vue-toastification'
import "vue-toastification/dist/index.css";
// import Popper from "@popperjs/core"
// import "../public/assets/js/jquery-3.6.0"

import "../public/assets/css/app.min.css"
import "../public/assets/css/style.css"
import "../public/assets/css/components.css"
import "../public/assets/css/custom.css"


// import "../public/assets/bundles/apexcharts/apexcharts.min.js.js"
// import "../public/assets/js/page/index.js"
// import "./assets/js/app.min"
// import "./assets/js/scripts"
// import "./assets/js/custom"
// // import "./assets/js/popper"

createApp(App).use(store)
.component('ABDavidLogo',AbdavidLogo)
.component('CardComponent', CardComponent)
.component('TableLayout',TableLayout)
.use(Toast,{
    transition: "Vue-Toastification__slideBlurred",
    maxToasts: 20,
    newestOnTop: true,
    position: "top-center",
    timeout: 2000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    closeButtonClassName:"toastclose",
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
  })
.use(router).mount('#app')
