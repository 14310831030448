import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import HomeView from '../views/HomeView.vue'
import DashboardComponent from '@/components/DashboardComponent.vue'
import AboutView from '../views/AboutView.vue'
import Users from "@/views/UsersView.vue"
import LoginView from "@/views/LoginView.vue"
import UsersView from '@/views/UsersView.vue'
import EmployeesView from '@/views/EmployeesView.vue'
import VisitorLog from '@/views/VisitorLog.vue'
import EmployeeLog from "@/views/EmployeeLog.vue"
import ServiceLog from "@/views/ServiceLog.vue"
import InterviewLog from "@/views/InterviewLog.vue"
import MeetingLog from "@/views/MeetingLog.vue"
import SmsView from '@/views/SmsView.vue'
import SettingsView from "@/views/SettingsView.vue"
import MeetingsView from "@/views/MeetingsView.vue"
import DepartmentsView from "@/views/DepartmentsView.vue"
import PrebookView from "@/views/PrebookView.vue"
import EmployeeLogSingle from '@/views/EmployeesLogSingles.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/home',
    name: 'dash',
    component: DashboardComponent,
    meta:{requiresAuth:true},
    children:[
      {path:'/dashboard', name:'dashboard', component: HomeView},
      // {path:'/users', name:'users', component: UsersView},
      {path:'/employees', name:'employees', component: EmployeesView},
      {path:'/businesslog', name:'businesslog', component: VisitorLog},
      {path:'/employeeslog', name:'employeeslog', component: EmployeeLog},
      {path:'/employeeslog-single', name:'employeeslogsingle', component: EmployeeLogSingle},
      {path:'/logs', name:'servicelog', component: ServiceLog},
      {path:'/interviewlog', name:'interviewlog', component: InterviewLog},
      {path:'/meetinglog', name:'meetinglog', component: MeetingLog},
      // {path:'/sms', name:'sms', component: SmsView},
      {path:'/settings', name:'settings', component: SettingsView},
      {path:'/meetings', name:'meetings', component: MeetingsView},
      {path:'/departments', name:'department', component: DepartmentsView},
      {path:'/users', name:'users', component: UsersView},
      {path:'/booked', name:'booked', component: PrebookView},
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to,from,next)=>{
//   if(to.meta.requiresAuth && !store.state.user.token){
//     router.push({ name: "login" });
//   }
// });

export default router
