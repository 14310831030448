<template>
    <div>
        <h3>Employees</h3>
        <card-component>
            <a @click="addstaff" class="btn btn-primary col-sm-2 text-white mb-3">Add Employee</a>
              <table-layout>
            <template #head>
                <th>#</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Position</th>
                <th>Staff type</th>
                <th>Actions</th>
            </template>
            <tr v-for="(employee, index) in employees" :key="employee">
                <td>{{ index+1 }}</td>
                <td>{{ employee.name }}</td>
                <td>{{ employee.phone }}</td>
                <td>{{ employee.email }}</td>
                <td>{{ employee.position }}</td>
                <td>{{ employee.type }}</td>
                <td><a @click="editstaff(employee)" class="btn btn-secondary">Edit</a> | <a @click="delstaff(employee.mask)" class="btn btn-danger">Delete</a> | <a @click="print(employee.name,employee.mask,employee.pin)" class="btn btn-warning">Print scan code</a></td>
            </tr>
        </table-layout>
        <!-- <vue-qrcode value="Hello there"></vue-qrcode> -->
  
        </card-component>
      
    </div>
</template>

<script>
import CardComponent from '../components/CardComponent.vue'
import TableLayout from '../components/TableLayout.vue'
import axiosClient from '@/axios'
import moment from 'moment'
import toast from "@/components/Toast.vue"
import AddStaff from '@/components/AddStaff.vue'
import ScanQrVue from '../components/ScanQr.vue'


    export default {
  components: { TableLayout, CardComponent},
  data(){
    return {
        employees:'',
    }
  },
  mounted(){
   this.getEmployees();
  },
  methods:{
    getEmployees(){
      axiosClient.get('employees').then(({data})=>{
        this.employees = data.data;
    })
    },
    timeformat(time){
        if(time){
            return moment(time).format('h:m a');
        }
    },
    dateformat(date){
       return moment(date).format('ddd, Do MMM Y');
    },
    addstaff(){
      toast.clear();
      toast({component: AddStaff, listeners:{done: ()=>{this.getEmployees()}}},
      {timeout:false, icon:false, closeOnClick:false,closeButtonClassName:'toastclose'});
    },
    editstaff(employee){
      toast.clear();
      toast({component: AddStaff,props:{employee:employee}, listeners:{done: ()=>{this.getEmployees()}}},
      {timeout:false, icon:false, closeOnClick:false});
    },
    delstaff(id){
      if(confirm('Confirm employee delete')){
        axiosClient.post('delete-employee', {mask: id}).then(({data})=>{
          toast.success(data.message);
          this.getEmployees();
        })
      }

    },
    print(employee,code,pin){
      toast.clear();
      toast({component: ScanQrVue,props:{code:code,name:employee,pin:pin}},
      {timeout:false, icon:false, closeOnClick:false});
    }
  }
        
    }
</script>

<style>
.toastclose{
  
  height: 0px !important;;
}
</style>