import axios from "axios";
import store from "./store";

// axios.defaults.baseURL = "http://localhost:8000/";
// axios.defaults.withCredentials = true;
// axios.defaults.headers = { Accept: "application/json" };

// export default axiosClient;
const axiosClient = axios.create({
    // baseURL: "https://api.abdavid.wowlogbook.com/api/",
    // baseURL: "http://localhost:8000/api/",
    baseURL: "http://172.161.150.8/api/",
    withCredentials: true,
    headers:{Accept: "application/json"}
});

axiosClient.interceptors.request.use((config) => {
  config.headers.Authorization = "Bearer "+store.state.user.token;
  config.headers.Content_Type = 'application/json';
  return config;
});

export default axiosClient;
