<template>
    <div>
<form @submit.prevent="addDept" method="post">
    <div class="form-group">
        <label for="" class="text-white">Name</label>
        <input v-model="dept.name" type="text" class="form-control" />
        <!-- <span class="text-danger">Ieeror</span> -->
    </div>
    <button type="submit" class="btn btn-secondary text-dark">{{ department != null ? 'Update' : 'Save' }}</button>
</form>
    </div>
</template>

<script>
import axiosClient from '@/axios';
import toast from './Toast.vue';

    export default {
        data(){
            return {
                dept:{},
            }
        },
        props:{
            department: {}
        },
        mounted(){
            if(this.department){
               this.dept = this.department;
            }
            // console.log('add staff');
        },
        methods:{
            addDept(){
                if(!this.department){
                    axiosClient.post('add-dept', this.dept).then(({data})=>{
                    toast.clear();
                    toast.success(data.message);
                    this.$emit('done');
                }).catch(({response})=>{
                    console.log(response.data.errors)
                    toast.error("All Fields are required");
                })
                }else{
                    axiosClient.post('update-dept', this.dept).then(({data})=>{
                    toast.clear();
                    toast.success(data.message);
                    this.$emit('done');
                }).catch(({response})=>{
                    console.log(response.data.errors)
                    toast.error("All Fields are required");
                })
                }
                
            }
        }
        
    }
</script>

<style lang="scss" scoped>

</style>