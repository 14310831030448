<template>
    <div class="" style="width:600px">
        <div class="d-grid mb-3" id="document">
            <center>
        <div class="d-flex justify-content-center card text-center p-5" id="qrcode">
            <p style="font-size: 40px; color:black; text-align:center">{{name}}</p>
            <p style="font-size: 30px; color:black; text-align:center">{{pin}}</p>
            <vue-qrcode style="margin-left:200px;"
      :value="code"
      :options="{
        width:300,
      }"
    ></vue-qrcode>
        </div>
    </center>
    </div>
<a @click="print" class="btn btn-secondary text-dark">Print</a>
<a @click="close" class="btn btn-danger text-white ml-4">Close</a>
    </div>
</template>

<script>
import AbdavidPrint from './AbdavidPrint.vue';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import html2pdf from "html2pdf.js"
import toast from './Toast.vue';

    export default {
  components: { AbdavidPrint, VueQrcode },
        data(){
            return {

            }
        },
        props:{
            code:'',
            name:'',
            pin:''
        },
        methods:{
            print(){
                html2pdf(document.getElementById('qrcode'),{margin:0,filename:this.name+".pdf"});
                // window.print();
                // let contents = document.getElementById("printable").innerHTML;
                // let frame1 = document.createElement('iframe');
                // frame1.name = "frame1";
                // frame1.style.position = "absolute";
                // frame1.style.top = "-1000000px";
                // document.body.appendChild(frame1);
                // let frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
                // frameDoc.document.open();
                // frameDoc.document.write('<html lang="en"><head><title>Employee Code</title>');
                // frameDoc.document.write('<link rel="stylesheet" type="text/css" href="..public/assets/css/print.css"/>');
                // frameDoc.document.write('</head><body>');
                // frameDoc.document.write(contents);
                // frameDoc.document.write('</body></html>');
                // frameDoc.document.close();
                // setTimeout(function () {
                //     window.frames["frame1"].focus();
                //     window.frames["frame1"].print();
                //     document.body.removeChild(frame1);
                // }, 1000);
                // return false;
            },
            close(){
                toast.clear();
            }
        }
    }
</script>

<style scoped>
 #document{
    margin:0 auto;
    text-align: center;
  }
</style>