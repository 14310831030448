<template>
    <div>
        <h3>Interview Logs - {{ dateformat() }}</h3>
        <card-component>
              <table-layout>
            <template #head>
                <th>#</th>
                <th>Name</th>
                <th>Time in</th>
                <th>Time out</th>
            </template>
            <tr v-for="(employee, index) in employees" :key="employee">
                <td>{{ index+1 }}</td>
                <td>{{ employee.name }}</td>
                <td>{{ timeformat(employee.created_at) }}</td>
                <td>{{ timeformat(employee.updated_at) }}</td>
            </tr>
            <!-- {{ employees }} -->
        </table-layout>
        </card-component>
      
    </div>
</template>

<script>
import CardComponent from '../components/CardComponent.vue'
import TableLayout from '../components/TableLayout.vue'
import axiosClient from '@/axios'
import moment from 'moment'

    export default {
  components: { TableLayout, CardComponent },
  data(){
    return {
        employees:'',
    }
  },
  mounted(){
    axiosClient.post('logs',{purpose: "Interview"}).then(({data})=>{
        this.employees = data.data;
    })
  },
  methods:{
    timeformat(time){
        if(time){
            return moment(time).format('h:m a');
        }
    },
    dateformat(date){
       return moment(date).format('ddd, Do MMM Y');
    }
  }
        
    }
</script>

<style lang="scss" scoped>

</style>