<template>
    <div>
        <h3>Booked Visitors</h3>
        <card-component>
            <a @click="addstaff" class="btn btn-primary col-sm-2 text-white mb-3">Book Visitor</a>
              <table-layout>
            <template #head>
                <th>#</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Company</th>
                <th>Booked by</th>
                <th>Actions</th>
            </template>
            <tr v-for="(visitor, index) in booked" :key="visitor">
                <td>{{ index+1 }}</td>
                <td>{{ visitor.name }}</td>
                <td>{{ visitor.phone }}</td>
                <td>{{ visitor.email }}</td>
                <td>{{ visitor.company }}</td>
                <td>{{ visitor.employee_id }}</td>
                <td><a @click="editstaff(visitor)" class="btn btn-secondary">Edit</a> | <a @click="delstaff(visitor.mask)" class="btn btn-danger">Delete</a></td>
            </tr>
        </table-layout>
        </card-component>
      
    </div>
</template>

<script>
import CardComponent from '../components/CardComponent.vue'
import TableLayout from '../components/TableLayout.vue'
import axiosClient from '@/axios'
import moment from 'moment'
import toast from "@/components/Toast.vue"
import BookVisitor from "@/components/BookVisitor.vue"

    export default {
  components: { TableLayout, CardComponent },
  data(){
    return {
        booked:'',
    }
  },
  mounted(){
   this.getvisitors();
  },
  methods:{
    getvisitors(){
      axiosClient.get('visitor/booked').then(({data})=>{
        this.booked = data.data;
    })
    },
    timeformat(time){
        if(time){
            return moment(time).format('h:m a');
        }
    },
    dateformat(date){
       return moment(date).format('ddd, Do MMM Y');
    },
    addstaff(){
      toast.clear();
      toast({component: BookVisitor, listeners:{done: ()=>{this.getvisitors()}}},
      {timeout:false, icon:false, closeOnClick:false});
    },
    editstaff(visitor){
      toast.clear();
      toast({component: BookVisitor,props:{visitor:visitor}, listeners:{done: ()=>{this.getvisitors()}}},
      {timeout:false, icon:false, closeOnClick:false});
    },
    delstaff(id){
      if(confirm('All records associated to this vistor will be deleted. Continue?  ')){
        axiosClient.post('visitor/delete-visitor', {mask: id}).then(({data})=>{
          toast.success(data.message);
          this.getvisitors();
        })
      }

    }
  }
        
    }
</script>

<style lang="scss" scoped>

</style>