<template>
  <div>
    <div class="emp" v-if="logs.length == 0">
      <h3>Employees</h3>
      <card-component>
        <table-layout>
          <template #head>
            <th>#</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Staff type</th>
            <th>Actions</th>
          </template>
          <tr v-for="(employee, index) in employees" :key="employee">
            <td>{{ index + 1 }}</td>
            <td>{{ employee.name }}</td>
            <td>{{ employee.phone }}</td>
            <td>{{ employee.email }}</td>
            <td>{{ employee.type }}</td>
            <td>
              <a
                @click="staffReport(employee.name, employee.mask)"
                class="btn btn-primary text-white"
                >View Report</a
              >
            </td>
          </tr>
        </table-layout>
        <!-- <vue-qrcode value="Hello there"></vue-qrcode> -->
      </card-component>
    </div>
    <div v-else>
      <h1 class="text-center">{{ employee }}</h1>

      <h4 class="text-center">Employee Logs - {{ day }}</h4>
      <card-component>
        <div class="d-flex justify-content-between">
          <a @click="logs = []" class="btn btn-primary text-white col-sm-2 mb-3"
            >View Employees</a
          >
          <a @click="excel" class="btn btn-danger text-white col-sm-2 mb-3"
            >Export to Excel</a
          >
        </div>
        <table-layout>
          <template #head>
            <th>#</th>
            <th>Time in</th>
            <th>Time out</th>
            <!-- <th>Images</th> -->
          </template>
          <tr v-for="(employee, index) in logs" :key="employee">
            <td>{{ index + 1 }}</td>
            <td>{{ employee.checkin }}</td>
            <td>{{ employee.checkout }}</td>
            <!-- <td>
              <div class="imgs">
                <img
                  src="http://localhost:8000/employee/Sponge%20bob_38.png"
                  alt="Checkin image"
                  srcset=""
                  style="width:200px;"
                />
              </div>
              <div class="imgs">
                <img :src="baseimg + checkout_image" alt="Checkout image" srcset="" style="width:200px;"/>
              </div>
            </td> -->
          </tr>
          <!-- {{ employees }} -->
        </table-layout>
      </card-component>
    </div>
  </div>
</template>

<script>
import CardComponent from "../components/CardComponent.vue";
import TableLayout from "../components/TableLayout.vue";
import axiosClient from "@/axios";
import moment from "moment";
import toast from "@/components/Toast.vue";
import HistoryComponentVue from "../components/HistoryComponent.vue";
import { json2excel, excel2json } from "js2excel";
import ImageComponentVue from "../components/ImageComponent.vue";

export default {
  components: { TableLayout, CardComponent },
  data() {
    return {
      employees: "",
      logs: [],
      day: "",
      // baseimg: "http://localhost:8000/employee/",
      // baseimg: 'http://172.161.150.8/employee/',
      employee: "",
    };
  },
  mounted() {
    this.getEmployees();
  },
  methods: {
    getEmployees() {
      axiosClient.get("employees").then(({ data }) => {
        this.employees = data.data;
      });
    },
    // timeformat(time) {
    //   if (time) {
    //     return moment(time).format("ddd, Do MMM Y h:mm a");
    //   }
    // },
    expand(checkin, checkout) {
      toast.clear();
      toast(
        {
          component: ImageComponentVue,
          props: { checkinImg: checkin, checkoutImg: checkout },
        },
        { timeout: false, icon: false, closeOnClick: false }
      );
    },
    excel() {
      try {
        json2excel({
          data: this.logs,
          name: "AB&DAVID " + this.employee + " report",
          formateDate: "yyyy/mm/dd",
        });
      } catch (e) {
        console.error("export error");
      }
    },
    staffReport(name, mask) {
      this.employee = name;
      toast.clear();
      toast(
        {
          component: HistoryComponentVue,
          props: { employee: mask, which: "employee" },
          listeners: {
            viewReport: (data) => {
              toast.clear();
              if (data.history.length == 0) {
                toast.success("No logs available for employee");
              } else {
                this.logs = data.history;
                this.day = data.range;

              }
            },
          },
        },
        { timeout: false, icon: false, closeOnClick: false }
      );
    },
  },
};
</script>

<style>
.toastclose {
  height: 0px !important;
}
</style>
