<template>
    <div class="loder">
    </div>
</template>

<style>
.loder{
    border-radius: 100px;
    border: 3px solid rgb(204, 200, 200);
    border-left: 3px solid rgb(255, 255, 255);
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0%{
        transform : rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
</style>