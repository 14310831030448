<template>
    <div>
        <h3>Business Clients Log - {{ dateformat() }}</h3>
        <card-component>
              <table-layout>
            <template #head>
                <th>#</th>
                <th>Name</th>
                <th>Time in</th>
                <th>Time out</th>
                <th>Action</th>
            </template>
            <tr v-for="(visitor, index) in business" :key="visitor">
                <td>{{ index+1 }}</td>
                <td>{{ visitor.name }}</td>
                <td>{{ timeformat(visitor.created_at) }}</td>
                <td>{{ timeformat(visitor.updated_at) }}</td>
                <td><a @click="print" class="btn btn-primary text-white">Print tag</a></td>
            </tr>
            <!-- {{ visitors }} -->
        </table-layout>
        </card-component>
    </div>
</template>

<script>
import CardComponent from '../components/CardComponent.vue'
import TableLayout from '../components/TableLayout.vue'
import axiosClient from '@/axios'
import moment from 'moment'

    export default {
  components: { TableLayout, CardComponent },
  data(){
    return {
        business:'',
    }
  },
  mounted(){
    axiosClient.post('logs',{purpose: "Business"}).then(({data})=>{
        this.business = data.data;
    })
  },
  methods:{
    timeformat(time){
        if(time){
            return moment(time).format('h:mm a');
        }
    },
    dateformat(date){
       return moment(date).format('ddd, Do MMM Y');
    },
   
  }
        
    }
</script>

<style lang="scss" scoped>

</style>