<template>
  <div>
    <a
      @click="reload"
      class="btn btn-secondary bg-primary text-white mt-0 mb-4"
      v-if="logs != []"
      ><strong class="font-18">Reload data</strong>
    </a>
    <h3>Employee Logs - {{ day }}</h3>
    <card-component>
      <div class="d-flex justify-content-between">
        <a
          @click="filter"
          class="btn btn-primary text-white col-sm-2 mb-3"
          v-if="logs != []"
          >View history</a
        >
        <a @click="excel" class="btn btn-danger text-white col-sm-2 mb-3"
          >Export to Excel</a
        >
      </div>
      <table-layout>
        <template #head>
          <th>#</th>
          <th v-if="history">Date</th>
          <th>Name</th>
          <th>Phone</th>
          <th>Time in</th>
          <th>Time out</th>
          <th>Action</th>
        </template>
        <tr v-for="(employee, index) in employees" :key="employee">
          <td>{{ index + 1 }}</td>
          <td v-if="history">{{ employee.date }}</td>
          <td>{{ employee.name }}</td>
          <td>{{ employee.phone }}</td>
          <td>{{ employee.time_in }}</td>
          <td>{{ employee.time_out }}</td>
          <td>
            <a
              @click="expand(employee.image, employee.outimage)"
              class="btn btn-primary text-white"
              >View images</a
            >
          </td>
        </tr>
        <!-- {{ employees }} -->
      </table-layout>
    </card-component>
  </div>
</template>

<script>
import CardComponent from "../components/CardComponent.vue";
import TableLayout from "../components/TableLayout.vue";
import axiosClient from "@/axios";
import moment from "moment";
import toast from "@/components/Toast.vue";
import HistoryComponent from "@/components/HistoryComponent.vue";
import { json2excel, excel2json } from "js2excel";
import ImageComponentVue from "../components/ImageComponent.vue";

export default {
  components: { TableLayout, CardComponent },
  data() {
    return {
      employees: "",
      day: "",
      history: false,
    };
  },
  mounted() {
    // console.log(process.env);
    if (this.logs) {
      this.employees = this.logs;
      this.day = this.range;
    } else {
      this.getEmpLog();
    }
  },
  props: {
    logs: [],
    range: "",
  },
  methods: {
    getEmpLog() {
      axiosClient.get("employees-log").then(({ data }) => {
        this.day = this.dateformat();
        this.employees = data.data;
      });
    },
    timeformat(time) {
      if (time) {
        return moment(time).format("h:mm a");
      }
    },
    dateformat(date) {
      return moment(date).format("ddd, Do MMM Y");
    },
    filter() {
      toast.clear();
      toast(
        {
          component: HistoryComponent,
          listeners: {
            viewReport: (data) => {
              this.history = true;
              toast.clear();
              (this.employees = {}), (this.employees = data.history);
              this.day = data.range;
            },
          },
          props: { which: "employee" },
        },
        { timeout: false, icon: false, closeOnClick: false }
      );
    },
    expand(checkin, checkout) {
      toast.clear();
      toast(
        {
          component: ImageComponentVue,
          props: { checkinImg: checkin, checkoutImg: checkout },
        },
        { timeout: false, icon: false, closeOnClick: false }
      );
    },
    excel() {
      try {
        json2excel({
          data: this.employees,
          name: "AB&DAVID Employees report",
          formateDate: "yyyy/mm/dd",
        });
      } catch (e) {
        console.error("export error");
      }
    },
    reload() {
      this.history = false;
      this.getEmpLog();
    },
  },
};
</script>

<style lang="scss" scoped></style>
