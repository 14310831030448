<template>
    <div>
        <form @submit.prevent="passchange" method="post">
            <div class="form-group">
                <label for="" class="text-white">Enter Old Password</label>
                <input type="password" class="form-control" v-model="password.old" id="">
            </div>
            <div class="form-group">
                <label for="" class="text-white">Enter New Password</label>
                <input type="password" class="form-control" v-model="password.new" id="">
            </div>
            <div class="form-group">
                <label for="" class="text-white">Confirm New Password</label>
                <input type="password" class="form-control" v-model="password.new_confirm" id="">
            </div>
            <button type="submit" class="btn btn-secondary">Change</button>
        </form>
    </div>
</template>

<script>
import axiosClient from '@/axios';
import toast from "@/components/Toast.vue"

    export default {
        data(){
            return {
                password:{}
            }
        },
        methods:{
            passchange(){
                if(this.password.new != this.password.new_confirm){
                    toast.error("New Password does not match");
                }else{
                     axiosClient.post('changepass', this.password).then(({data})=>{
                          toast.clear();
                          this.$emit('changed');
                }).catch(({response})=>{
                    toast.error(response.data.message);
                });
                }
               

            }
        },
        mounted(){
            $("#passicon").addClass("bx-hide");
        const Showpass = $('#passicon');

        $("#passicon").on("click", function () {
            $("#passicon").toggleClass(["bx-hide", "bx-show"]);
            if(Showpass.hasClass('bx-hide')){
                $('#password').attr('type', 'password');
            }else{
                $('#password').attr('type', 'text');
            }
        });
        }
    }
</script>

<style lang="scss" scoped>

</style>