<template>
    <div id="app">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 left">
            <h2>Hello Admin, <br> Welcome back.</h2>
          </div>

          <div class="col-md-6 right">
            <div class="card shadow-lg form-card">
             <div class="text-center">
               <img src="../abdavid-logo.png" style="width:200px" class="my-4" alt="" srcset="">
             </div>
            
            <div class="text-center px-5">
              <h5>Please sign in with your administrator account.</h5>
            </div>

             <div class="form-container">
                <p class="text-danger text-center">
                  {{ error }}
                </p>

                <form method="POST" @submit.prevent="login" class="needs-validation">
                  <div class="form-group">
                    <div class="d-flex justify-content-between">
                      <label for="password" class="">USERNAME</label>
                      <!-- <div class="">
                        <a href="#" class="text-small">
                          Forgot Password?
                        </a>
                      </div> -->
                    </div>
                    <input id="username" type="text" class="form-control" v-model="form.username" tabindex="1" required autofocus>
                    <div class="invalid-feedback">
                      Please fill in your username
                    </div>
                  </div>
                  <div class="form-group d-grid justify-content-center">
                    <div class="d-flex justify-content-between">
                      <label for="password" class="">PASSWORD</label>
                      <!-- <div class="">
                        <a href="#" class="text-small">
                          Forgot Password?
                        </a>
                      </div> -->
                    </div>
                    <input id="password" type="password" class="form-control" v-model="form.password" tabindex="2" required>
                    <div class="invalid-feedback">
                      please fill in your password
                    </div>
                  </div>
                  <!-- <div class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" name="remember" class="custom-control-input" tabindex="3" id="remember-me">
                      <label class="custom-control-label" for="remember-me">Remember Me</label>
                    </div>
                  </div> -->
                  <div class="form-group">
                    <button type="submit" class="btn btn-primary btn-lg btn-block" tabindex="4">
                      <div class="d-flex justify-content-center align-items-center" v-if="loading">
                                    <button-loader class="mr-2"></button-loader>
                                    Logging in
                                </div>
                                <div class="" v-if="!loading">
                                    Sign in
                                </div>
                    </button>
                  </div>
                </form>
             </div>
             <div class="text-center mt-4 mb-3">
              <div class="text-job text-muted">powered by wowlogbook.com</div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosClient from '@/axios';
import router from '@/router';
import toast from "@/components/Toast.vue"
import store from "@/store"
import ButtonLoader from "@/components/ButtonLoader.vue"

export default {
  data(){
    return {
      form:{},
      error: '',
      loading:false
    }
  },
  components:{
ButtonLoader
  },
  methods:{
    login(){
      this.loading = true
      axiosClient.post('/login',this.form).then(({data})=>{
        this.loading = false;
        sessionStorage.setItem('token', data.data[0].token);
        // store.state.user.data['role'] = data.data[0].role;
        sessionStorage.setItem('role',data.data[0].role);
        // data.data[0].role is for getting user role
        this.$router.push('/dashboard');
      }).catch(({response})=>{
        this.loading = false;
        this.error = response.data.message;
        // console.log(response);
      })
    }
  }
}


</script>

<style scoped>
.left
{
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100vh;
  background-image: url('../loginimg.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 25px;
  box-shadow: inset 0 0 0 2000px rgba(31, 30, 31, 0.6);
}

.left h2
{
  color: white;
  font-size: 50px;
}

.right .form-card
{
  width: 400px;
  margin: 50px auto;
}

.right .form-card .form-container
{
  padding: 20px 50px;
}
.login{
  width: 100vw;
  height: 100vh;
  position: absolute;
  right: 0; 
}

.adm{
  font-size: x-large;
  font-weight: 500;
}
</style>